.option-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  //align-items: flex-start;
  margin-bottom: -1em;

  &__accordion {
    flex: 1 1 100%;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    //transition: $transition;
    visibility: hidden;

    &.--visible {
      max-height: 1000px;
      opacity: 1;
      //transition: $transition-slow;
      visibility: visible;
    }
  }

  &__accordion-toggle {
    flex: 1 0 100%;
    position: relative;
    display: block;
    margin-top: 0.25em;
    margin-bottom: 1em;
    text-align: center;

    &__link {
      position: relative;
      display: inline-block;

      &:hover,
      &:focus {
        color: $primary;
      }
    }
  }
}

.button--option {
  cursor: pointer;
  position: relative;
  display: inline-block;
  //margin: 0.25em 0.65em 1.35em;
  //padding: 0.5em 1.5em;
  color: $gray-700;
  font-family: $font-family-sans-serif;
  //font-size: 1em;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.6;
  text-align: center;
  text-decoration: none;
  background-color: $white;
  border: 2px solid $secondary;
  //border-radius: 5em;
  border-radius: 5px;
  outline: none;

  font-size: 0.8em;
  //margin: 0.25em;
  margin: 0.15em;
  padding: 0.25em 0.5em;
  min-height: 3em;
  width: 30%;
  //transition: $transition;

  &:hover,
  &:focus {
    color: $white;
    background-color: $secondary;
    box-shadow: 0 1px 4px 0 #00000033;
    text-decoration: none;
  }

  .card[id^='multi-select-'] & {
    &:hover,
    &:focus {
      color: $gray-700;
      background-color: $white;
    }
  }

  &:visited {
    color: $gray-700;

    &:hover,
    &:focus {
      color: $white;
      text-decoration: none;
    }
  }

  &.--selected {
    color: $white;
    background-color: $primary;
    border-color: darken($primary, 5%);
  }
  &.--active {
    color: $white;
    background-color: $secondary;

    .card[id^='multi-select-'] & {
      &:hover,
      &:focus {
        color: $white;
        background-color: $secondary;
      }
    }
  }
}
