.demo-card-types,
.demo-card-material {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;

  .card--has-title {
    flex: 0 0 100%;
  }
}

.card-grid__card-container {
  position: relative;
  flex: 1 0 30%;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  margin-top: $card-grid-top-offset;
  margin-left: auto;

  .card--option {
    flex: 1;

    &:hover {
      border-color: #255dff;
    }
  }
}

.card {
  $card: &;

  display: flex;
  flex-flow: column nowrap;
  position: relative;
  //transition: $transition;

  &-slider {
    &__nav {
      &__prev,
      &__next {
        margin-top: -2rem;
      }
    }

    .slick-track {
      padding-bottom: 2rem;
    }
  }

  &--basic {
    padding: 1.25rem 3.35rem 2.5rem;
    margin-bottom: 0.875rem;
    text-align: center;
    background-color: $white;
    border: 1px solid $gray-light;
    border-radius: 3px;
    box-shadow: 0 3px 4px 0 rgba($black, 0.1);

    &:hover,
    &:focus {
      box-shadow: 0 6px 15px 0 rgba($black, 0.15);
    }
  }

  &--has-title {
    h5 {
      color: $charcoal;
      font-weight: bold;
      margin-bottom: 1.55em;
    }
  }

  &--has-range {
    padding-bottom: 4.125rem;
  }

  &--option {
    cursor: pointer;
    align-items: center;
    margin-bottom: 1em;
    max-width: 11.35rem;
    background-color: $white;
    border: 1px solid $gray-light;
    border-radius: 3px;
    box-shadow: 0 3px 4px 0 rgba($black, 0.1);
    font-size: 0.75rem;
    text-align: center;

    &:hover,
    &:focus {
      box-shadow: 0 6px 15px 0 rgba($black, 0.15);
    }

    &.--selected {
      border-color: $blue;
    }

    & + & {
      margin-left: 0.8em;
    }

    h6 {
      margin: 1.6em 1em 1.4em;
      color: $charcoal;
      font-size: rem(16);
      font-weight: bold;
      line-height: line-height(20, 16);
    }

    #{$card}__bottom {
      $bottom: &;

      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 100%;
      border-radius: 0 0 3px 3px;

      &--split {
        justify-content: space-between;

        .card__bottom__file-type {
          flex: 0 0 49%;
          border-radius: 0 0 0 3px;
        }
      }

      &__file-type {
        flex: 1;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0.8em 0.5em;
        background-color: $gray-light-b;
        border-radius: 0 0 3px 3px;
        color: $gray-d;
        font-size: rem(10);
        font-weight: normal;
        letter-spacing: 0.15px;
      }

      &__tested {
        flex: 0 0 49%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0.8em 0.5em;
        background: $gray-light-b;
        border-radius: 0 0 3px 0;
        color: $gray-d;
        font-size: rem(10);

        img {
          width: rem(10);
          height: auto;
          margin-right: 0.5em;
        }
      }
    }
  }

  &--multi-select {
    & > h5 {
      margin-bottom: 0.25em;
    }

    & > span {
      margin-bottom: 1rem;
      color: $gray-c;
      font-weight: 600;
    }

    .button--option {
      letter-spacing: 0.035ch;
    }
  }

  &--material {
    width: rem(278);
    background-color: $white;
    border: 1px solid $gray-light;
    border-radius: 3px;
    box-shadow: 0 3px 4px 0 rgba($black, 0.1);

    &:hover,
    &:focus {
      box-shadow: 0 6px 15px 0 rgba($black, 0.15);
    }

    @include max(1329) {
      width: calc(100% - 2em);
    }

    & + & {
      margin-left: 2em;
    }

    .card {
      &__thumbnail {
        flex: 0 0 rem(144);
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 1em 1em 0.25em;
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        z-index: 1;

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 0;
          background: linear-gradient(180deg, rgba($black, 0) 0%, $black 100%);
        }

        &__category {
          font-size: rem(12);
          color: $white;
          font-weight: bold;
          letter-spacing: 1px;
          text-transform: uppercase;
          line-height: 1;
          z-index: 1;
        }
      }

      &__content {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        padding: 1em;
        text-align: left;

        .card__title {
          flex: 100%;
          font-size: rem(12);
          font-weight: bold;
        }

        .card__subtitle {
          flex: 100%;
          font-size: rem(12);
          color: $gray-a;
        }

        .card__file-type {
          flex: 100%;
          color: $gray-d;
          font-size: rem(10);
        }

        .card__subtitle + .card__file-type {
          margin-top: 0.5em;
        }

        .card__tested {
          position: absolute;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          right: 2em;
          bottom: 2em;
          color: $gray-d;
          font-size: rem(10);

          img {
            width: rem(10);
            height: auto;
            margin-right: 0.5em;
          }
        }
      }
    }

    .button {
      margin-top: 2em;
    }
  }

  &--my-material {
    flex-flow: row nowrap;
    width: 100%;
    min-height: rem(160);

    & + & {
      margin-left: unset;
    }

    .material-card {
      &__left,
      &__middle,
      &__right {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
      }

      &__left {
        flex: 0 0 calc(#{rem(22)} + #{rem(39)});
        align-items: center;
        padding: rem(15) 0;
        background-color: $gray-light-b;
      }

      &__middle {
        flex: 1 1 auto;
        align-items: flex-start;
        padding: rem(15);
      }

      &__right {
        flex: 0 0 6.25rem;
        align-items: center;
        padding: rem(15) rem(15) 0 0;
      }

      &__icon {
        max-width: rem(39);
        margin-bottom: auto;
      }

      &__heading {
        margin-bottom: 0.25em;
        line-height: line-height(19, 16);
      }

      &__type {
        font-size: rem(12);
        line-height: 1.2;
      }

      &__options {
        position: relative;
        margin-top: auto;
      }

      &__tested {
        display: none;
        flex: 0 0 auto;
        background: $gray-light-b;
        align-items: center;
        font-size: rem(9);
        color: $gray-d;
        padding: 0.8em 0.5em;
        justify-content: center;
        border-radius: 0 0 3px 0;

        img {
          width: rem(9);
          height: auto;
          margin-right: 0.5em;
        }
      }

      &__status {
        display: inline-block;
        margin: 0.25em 0 0.5em;
        padding: 0 2.4em 0 1.4em;
        background-color: $gray-b;
        font-size: rem(9);
        font-weight: normal;
        letter-spacing: 0.0375em;
        line-height: line-height(16, 9);
        text-align: center;
        border: 2px solid transparent;
        border-radius: 5em;

        &::after {
          right: 0.95em;
          border-top-color: $gray-a;
        }
      }
    }

    &.material--tested .material-card__tested {
      display: inline-flex;
    }

    &.material--draft,
    &.material--final {
      .material-card__status {
        display: inline-block;
      }
    }

    &.material--draft .material-card__status {
      color: $orange;
      background-color: $orange-muted;
      border: 2px solid $orange-muted;

      &::after {
        border-top-color: $orange;
      }
    }

    &.material--final .material-card__status {
      color: $green;
      background-color: $green-muted;
      border: 2px solid $green-muted;

      &::after {
        border-top-color: $green;
      }
    }
  }

  &-label {
    &--how-to-use {
      cursor: pointer;
      position: absolute;
      padding: 0.5em 1em 0.6em;
      right: 0;
      bottom: calc(100% + 5px);
      border: none;
      border-radius: 3px;
      background-color: rgba($blue-light-2, 0);
      outline: 0;
      color: $gray-e;
      font-size: rem(10);
      font-weight: 300;
      letter-spacing: 0.01em;
      line-height: 1;
      //transition: $transition;

      &:hover,
      &[aria-expanded='true'],
      &.--active {
        background-color: rgba($blue-light-2, 1);
        color: $blue;
      }
    }
  }

  &--with-header {
    padding: 0;
    margin-bottom: 0.875rem;
    text-align: center;
    background-color: $white;
    border: 1px solid $gray-light;
    border-radius: 3px;
    box-shadow: 0 3px 4px 0 rgba($black, 0.1);
    overflow: hidden;

    &:hover,
    &:focus {
      box-shadow: 0 6px 15px 0 rgba($black, 0.15);
    }
  }
}
