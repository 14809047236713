@import 'sidebar-gi';
@import 'font-picker';
@import 'form';
@import 'logic-tagging';
@import 'option-button';
@import 'variables';
@import 'utilities';
@import 'cards';
@import 'nouislider';

#data-table_wrapper {
  overflow-y: scroll;
}

#data-listing-heading {
  padding: 1em 0;
  h1 {
    font-size: 1.25em;
  }
}

#data-listing {
  select {
    word-wrap: break-word;
    max-width: 20%;
  }

  table {
    //table-layout: fixed;
  }
  th {
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
  }

  .table-actions {
    display: flex;
    flex-wrap: wrap;
    width: 21vw;
    justify-content: end;
    button {
      margin: 0.25em;
      width: 2.5em;
    }
  }
}
.section-loader {
  top: 0;
  width: 100%;
  max-height: 80vh;
  height: 100%;
  position: absolute;
  background: #ffffff00;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: wait;

  .loader-circle {
    display: inline-block;
    transform: translateZ(1px);
  }
  .loader-circle > div {
    display: inline-block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    background: $primary;
    animation: loader-circle 9.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  @keyframes loader-circle {
    0%,
    100% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(1800deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
      transform: rotateY(3600deg);
    }
  }

  .loader-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .loader-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $primary;
    animation: loader-grid 1.2s linear infinite;
  }
  .loader-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
  }
  .loader-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
  }
  .loader-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
  }
  .loader-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
  }
  .loader-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
  }
  .loader-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
  }
  .loader-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
  }
  .loader-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
  }
  .loader-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
  }
  @keyframes loader-grid {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: $primary;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 1s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 1s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 1s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 1s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
}

.ql-container.ql-snow {
  min-height: 12em;
}

#data-listing-heading {
  position: relative;
  #table-new-button {
    position: absolute;
    right: 0.5em;
    top: 0.75em;
  }
  #template-import-button {
    position: absolute;
    right: 4.5em;
    top: 0.75em;
  }
}

.upload-section {
  background: #ffffff;
  border: 0.9px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 2rem;
  .upload-area {
    background: #f5f5f5;
    border: 1px dashed #939393;
    border-radius: 3px;
    // width: 380px;
    width: 100%;
    max-width: 380px;
    height: 220px;
    margin: 0 auto;

    div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      p {
        margin-bottom: 0;
      }
    }
  }
  .upload-link {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $purple;
    text-align: center;
    display: block;
    margin-top: 2rem;
  }
}

.canvas-container {
  position: relative;
  .matgen-drawing {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .matgen-reference {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

.fp-modal {
  width: 550px;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $primary;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.question-with-examples {
  cursor: pointer;
  &:hover {
    background-color: $primary;
    color: $white;
  }
}

#question-examples-div {
  max-width: 640px;
}

.example-actions {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  .icon-link {
    margin-right: 1em;
  }
}

.btn-primary:active,
.btn-primary:focus {
  color: #fff;
}

legend.normal {
  display: block;
  width: auto;
  padding: 0 5px;
  margin-bottom: 0;
  font-size: inherit;
  line-height: inherit;
  border: auto;
  border-bottom: none;
}

fieldset.normal {
  border: 2px groove threedface;
  padding: 5px;
}

.questionnaire-detail {
  margin-bottom: 0.5em;
  display: flex;
  div {
    white-space: nowrap;
    margin-right: 1em;
  }
}

.section-wrapper {
  padding: 1.5em;
}

.section-detail {
  display: flex;
  flex-wrap: wrap;
  div {
    white-space: nowrap;
    margin-right: 1em;
  }
}

.section-questions {
  margin-top: 1em;
}

.questions-controls,
.answers-controls {
  display: flex;
  margin-bottom: 0.75em;
}

.questionnaire-details-wrapper {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}

.section-detail-wrapper {
  display: flex;
}

.m4c-accordion-collapse {
  display: none;
}

.m4c-accordion-body {
  border: 1px solid #eee;
  margin-bottom: 1.5em;
  padding: 1em 0.75em;
}

.icon-link {
  cursor: pointer;
  color: $primary;
  &:hover {
    color: #333;
  }
}

.question-title,
.answer-title {
  flex-grow: 1;
}

.answer-title {
  svg {
    margin-right: 0.75em;
  }
}

#sort-div {
  padding: 2.5em;
  #sort-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      padding: 0.75em;
      border: 1px solid #eee;
      cursor: grab;
    }
  }
}

.section-wrapper .alert {
  padding-top: 1.5em;
  padding-bottom: 0.25em;
  text-align: center;
  white-space: normal;
  i,
  svg {
    margin-right: 0.75em;
  }
  p {
    color: #333;
  }
}

.questions-controller-title,
.answers-controller-title {
  flex-grow: 1;
}

.questions-controller,
.answers-controller {
  padding: 1.5em;
  border: 1px solid #eee;
}

.tab-pane {
  border: 1px solid #eee;
}

.questionnaire-detail,
.questionnaire-sections {
  border-radius: 4px;
  border: 1px solid #eee;
  padding: 1.5em 2.5em;
}

.answer-card,
.example-card {
  border: 1px solid #eee;
  padding: 0.75em;
  border-radius: 4px;
}

.answer-card,
.example-card {
  display: flex;
}
.questionnaire-details-wrapper {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}

#alert-modal {
  .alert {
    margin-bottom: 0;
  }
  .alert-danger {
    h4 {
      color: #fff !important;
    }
  }
}

.alert-modal-icon {
  margin-right: 2em;
}

.alert-modal-content {
  max-width: 480px;
  p {
    margin: 0 auto;
  }
}

.btn-close.alert-modal-close-button {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  span[aria-hidden='true'] {
    display: none;
  }
}

.form-text {
  text-align: center;
}

.node-bottom {
  //display: none;
}

.node-content .node-actions a.layer-action:hover {
  color: $primary;
}

#matgen-sidebar {
  padding-right: 0.5em;
  width: 27.5%;
  height: 90vh;
  overflow-y: scroll;
}

#matgen-signin-modal-modal-body {
  min-width: 420px;
}

#data-listing .table-striped > tbody > tr:nth-child(odd) {
  --bs-table-striped-bg: #fff;
}

.modal-buttons {
  text-align: center;
}

#alert-modal .modal-body {
  min-height: auto;
  min-width: auto;
  padding: 48px;
}

.matgen-actions {
  position: absolute;
  z-index: $middle-z-index;
  width: 100%;
  height: 100%;
}

.matgen-main {
  //pointer-events: none;
}

.modal-body {
  .card-group {
    flex-direction: column;
  }
  .card {
    margin-bottom: 1.25em;
    cursor: pointer;
    &:hover {
      box-shadow: 1px 1px 2px #333;
    }
    &.disabled {
      background-color: #dedede;
      color: #aaa;
      text-decoration: line-through;
    }
  }
  .card-title {
    //margin-left: 1.5em;
    display: flex;
    margin-right: 1.5em;
    .title-text {
      margin-left: 0.5em;
    }
  }
  .card-body {
    max-width: 36em;
    display: flex;
    align-items: center;
    .fa-stack {
      color: $secondary;
    }
  }
}

.alert + div {
  text-align: center;
}

.modal-backdrop {
  background-color: #ffffff;
  z-index: $modal-overlay-z-index;
}

.modal-content {
  //background-color: #ffffffee;
}

#alert-ok-button {
  margin-top: 24px;
}

.component-edit-item:hover {
  cursor: pointer;
}

.file-type .hide-file-type {
  display: none;
}

.paginate_button.page-item.active {
  z-index: -1;
}

#sidebar-title {
  font-size: 1em;
}

#sidebar.admin {
  //width: 220px;
  //min-width: 220px;
  .sidebar-actions {
    padding-left: 6px;
  }
}

#template-form .col-form-label {
  white-space: nowrap;
}

#template-form {
  min-width: 520px;
}

#template-form .col-sm-8 {
  min-width: 260px;
}

#page-thumb {
  box-shadow: 1px 1px 2px #cfcfcf;
}

.list-group-item.active {
  z-index: auto;
}

#modal-body #accordion {
  max-width: 85vw;
}

#options-container {
  min-width: 320px;
  min-height: 220px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.hidden-file-input {
  display: none;
}

.modal-body {
  min-height: 80px;
  min-width: 320px;
}

.preview-modal .modal-body {
  text-align: center;
}

#data-listing-heading {
  display: none;
}

.form-label {
  margin-top: 0.5em;
}

.unpublished {
  //opacity: 0.65;
}

.modal-dialog {
  //min-width: 35%;
  max-width: fit-content;
}

#user-email {
  color: $primary;
  &:hover {
    color: $primary;
    background-color: initial;
  }
}

#matgen-loader-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $max-z-index;
}

#hide-grid,
#show-grid {
  display: none;
}

.navbar-brand {
  margin-right: 9em;
}

.noUi-connect {
  background: $primary;
}
.noUi-horizontal .noUi-handle {
  border-color: $primary;
}

.tooltip {
  pointer-events: none;
  z-index: $max-z-index;
  font-weight: 500;
}

.bs-tooltip-top {
  margin-top: -1.5em;
}

.bs-tooltip-top {
  margin-top: -1.5em;
}

.badge {
  margin-top: -0.25em;
}

/*loading-indicator {
  z-index: 1;
  --overlay-bg-color: #fff;
  --overlay-color: #{$primary};
  --overlay-opacity: 0.8;
  --spinner-color-1: #{$primary};
  --spinner-color-2: #{$primary};
  --overlay-message-padding: 0;
}*/

.loading-indicator,
:root {
  z-index: 1;
  --overlay-bg-color: #fff;
  --overlay-color: #{$primary};
  --overlay-opacity: 0.5;
  --spinner-color-1: #{$primary};
  --spinner-color-2: #{$primary};
  --overlay-message-padding: 160px;
}

#page-loader {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-height: 100vh;
  max-width: 100vw;
}

#matgen-whole {
  width: 100%;
  height: 100%;
  background: var(--overlay-bg-color, #fff);
  transition: grayscale 2s, invert 2s, blur 2s;
}

.disabled-option {
  opacity: 0.5;
}

#progress-wrapper {
  position: absolute;
  bottom: 0;
  z-index: $context-menu-z-index;
  width: 100%;
  z-index: 1;
}

.progress {
  width: 100%;
  margin: auto;
}

.checkbox {
  label {
    margin-bottom: 0;
  }
}

.font-picker {
  .fp-modal {
    z-index: $modal-z-index;
  }

  .fp-modal-backdrop {
    background-color: $secondary;
    z-index: $modal-overlay-z-index;
    opacity: 0.5;
  }
}

.matgen-form .font-picker.fp-select {
  @extend .form-control;
  margin-bottom: -1px;
  border-radius: 0;
  color: #95a5a6;
  display: block;
  padding: 0;
  .fp-fontspec {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
    margin: 0;
  }
}

#object-explorer {
  width: 440px;
  max-height: 100%;
  min-height: 33%;
  overflow: auto;
  border: solid 1px #dfdfdf;
  box-shadow: 1px 1px 2px #cfcfcf;
  padding: 6px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2147483646;
  background: #fff;
}

#object-explorer-header {
  cursor: move;
  font-size: 0.75em;
  text-align: center;
}

#object-explorer-container {
  height: 20em;
  min-width: 20em;
  max-width: 30em;
  overflow: scroll;
  font-family: sans-serif;
  display: none;
  border: solid 1px #dfdfdf;
  box-shadow: 1px 1px 2px #cfcfcf;
  padding: 12px;
  position: fixed;
  bottom: 6px;
  right: 6px;
  z-index: 2147483647;
  background: #fff;
  --overlay-bg-color: #fff;
  --overlay-color: #333;
  --overlay-opacity: 0.8;
  --spinner-color-1: #333;
  --spinner-color-2: #333;
  --spinner-font-size: 6px;
}

#object-explorer .oe-array {
  color: #859900;
}

#object-explorer .oe-key {
  color: #002b36;
}

#object-explorer .oe-object {
  color: #b58900;
}

#object-explorer .oe-object {
  color: #b58900;
}

#object-explorer .oe-number {
  color: #6c71c4;
}

#object-explorer .oe-string {
  color: #dc322f;
}

#object-explorer .oe-boolean {
  color: #268bd2;
}

#object-explorer .oe-empty {
  color: #586e75;
}

#object-explorer .oe-image {
  color: #d33682;
}

#object-explorer .oe-textbox {
  color: #2aa198;
}

#object-explorer .oe-group {
  color: #268bd2;
}

#object-explorer .oe-null {
  color: #cb4b16;
}

#object-explorer ul,
#object-explorer ol {
  list-style-type: none;
  padding-left: 0.75em;
}

.matgen-hidden-canvas {
  opacity: 0;
  position: absolute;
  top: -999999px;
  left: -999999px;
}

#object-explorer li {
  cursor: auto;
  /*list-style-image : url('assets/circle-regular.svg');*/
}

#object-explorer li.collapsibleListOpen {
  cursor: pointer;
  /*list-style-image : url('assets/minus-square-regular.svg');*/
}

#object-explorer li.collapsibleListClosed {
  cursor: pointer;
  /*list-style-image : url('assets/plus-square-regular.svg');*/
}

.pcr-app {
  z-index: $max-z-index;
}

.pickr {
  display: inline-block;
  padding-left: 0.5em;
}

/*.matgen-main {
  border: 1px dashed #bbb;
}*/

.page-item.active .page-link {
  background-color: darken($primary, 20%);
}

.navbar .nav > li,
.dropdown-menu {
  z-index: $context-menu-z-index;
}

.dropdown-header {
  font-weight: bold;
  color: $secondary;
}

.option-actions {
  //position: absolute;
  //bottom: 0;
  //left: 0;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding: 0.25em;
  border-bottom: 1px solid $gray-300;
}

.option-action {
  margin: 0.25em;
}

.option-action:hover {
  color: $primary;
}

.nav-item:not(.disabled) {
  cursor: pointer;
}

.m4c-matgen.option-wrapper,
.option-wrapper {
  border: 1px solid #dedede;
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;
  background: url(/assets/img/editor-bg.png);
  position: relative;
  text-align: center;
  //max-width: 18%;
  height: fit-content;
  //max-width: 20%;

  .tooltip {
    opacity: 1;
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.5);
    height: 4rem;
    width: 4rem;
    pointer-events: all;
    user-select: all;

    svg {
      opacity: 1;
      color: red;
      width: 2rem;
      height: 2rem;
      pointer-events: none;
      user-select: none;
    }

    .favorited-heart {
      display: none;
    }

    .not-favorited-heart {
      display: block;
    }
  }

  .favorite-tooltip {
    .favorited-heart {
      display: block !important;
    }

    .not-favorited-heart {
      display: none !important;
    }
  }
}

.admin-option-container {
  max-width: 18%;
  margin-bottom: 1em;
  /*.option-wrapper {
    max-width: 100%;
  }*/
}

.option-wrapper:not(:first-of-type) {
  margin-top: 12px;
}

.option-wrapper:hover {
  border-color: $secondary;
}

.option-wrapper:active,
.option-wrapper.selected-option {
  border-color: $primary;
}

.option-wrapper input[type='checkbox'] {
  margin-right: 18px;
}

#context-menu {
  position: absolute;
  display: none;
  z-index: $context-menu-z-index;
}

#file-input {
  display: none;
}

#matgen-scale-container {
  //width: 800px;
  //border: 4px dashed #00000000;
  //stroke-dasharray: 5;
  //padding: 15px;
}

#google-signin2 {
  .abcRioButton {
    margin: 1em auto;
  }
}

.form-error {
  color: $danger;
  font-weight: bold;
  margin-bottom: 1em;
  display: none;
}

.form-success {
  color: $success;
  font-weight: bold;
  margin-bottom: 1em;
  display: none;
}

/*html {
  height: 100%;
}
body {
  //height: 100%;
  min-height: 100%;
  position: relative;
}*/
html {
  min-height: 100%;
  display: flex;
}

body {
  flex: 1;
}
body,
html {
  margin: 0;
  padding: 0;
}

#app {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: $secondary;
  font-family: $font-family-sans-serif;
}

#matgen-controller {
  //display: flex;
  //align-items: center;
  //flex-direction: column;
  //width: 100%;
  height: 100%;
  width: 100%;
}

.contained-canvas {
  .upper-canvas {
    border: 2px dashed #333;
  }
}

#matgen-controller .modal-backdrop.show {
  opacity: 0.9;
}

.matgen-help-modal .modal-content,
.matgen-508-modal .modal-content {
  max-width: 94vw;
}

#matgen-controller.editor {
  width: 75%;
  height: 90vh;
  overflow-y: scroll;
}

main {
  flex: 1;
  //overflow: auto;
  position: relative;
  display: flex;
  //flex-direction: row-reverse;
  width: 100vw;
  #form-container {
    width: 24em;
    margin: auto;
    //text-align: center;
  }

  #error-container {
    //width: 48em;
    margin: auto;
    margin-top: 4em;
    text-align: center;
    max-width: 50vw;
    margin: auto;
  }
}

.modal {
  z-index: $modal-z-index;
}

h1.nav-intra-section-title {
  font-size: 1.25em;
  padding: 0 18px;
  border-right: 2px solid $primary;
}

header {
  box-shadow: 0 1px 2px $gray-200;
  padding: 0.5em;
  margin-bottom: 0.5em;
  z-index: $content-z-index;

  .navbar {
    //z-index: 2147483000;
    padding: 0;
  }

  #main-nav {
    margin-left: 3em;
  }

  .logo-intranet {
    text-align: center;
    font-size: 0.6em;
    text-transform: uppercase;
    color: $secondary;
  }

  a {
    color: $secondary;
  }

  a:hover,
  a.nav-link:hover,
  a:hover .logo-intranet,
  a:hover h1,
  li.active a {
    color: $primary;
    text-decoration: underline;
  }
}

footer {
  box-shadow: 0 -1px 2px $gray-200;
  padding-top: 0.5em;
  margin-top: 0.5em;
  position: relative;
  z-index: $content-z-index;
}
