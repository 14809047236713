// Background- and text-color utility class generator
@use "sass:math";

@each $name, $color in $colors {
  .color--#{$name} {
    color: #{$color};
  }

  .bg--#{$name} {
    background-color: #{$color};
  }
}

// PX to EM Default Size and Function
$base-font-size: 16;

@function em($pixels, $context: $base-font-size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return math.div($pixels, $context) * 1em;
}

// PX to REM Function
@function rem($pixels, $context: $base-font-size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return math.div($pixels, $context) * 1rem;
}

// EM to Unitless Function
// TODO: Expand this to take any type of unit value and convert to plain decimal.
@function line-height($pixels, $context: $base-font-size) {
  @return math.div($pixels, $context) * 1;
}

// Media Query Shorthands
@mixin min($size) {
  @if (unitless($size)) {
    $size: $size * 1px;
  }

  @media screen and (min-width: #{$size}) {
    @content;
  }
}

@mixin max($size) {
  @if (unitless($size)) {
    $size: $size * 1px;
  }

  @media screen and (max-width: #{$size}) {
    @content;
  }
}

@mixin minmax($min, $max) {
  @if (unitless($min)) {
    $min: $min * 1px;
  }

  @if (unitless($max)) {
    $max: $max * 1px;
  }

  @media screen and (min-width: #{$min}) and (max-width: #{$max}) {
    @content;
  }
}

// Util class to prevent element flickering when using ScrollReveal.
html.sr .load-hidden {
  visibility: hidden;
}

.--no-scroll {
  overflow: hidden;
}

.--hidden {
  display: none;
}
