.m4c-dual-ring {
  display: inline-block;
  /*border: 1px solid #f80;*/
}
.m4c-dual-ring:after {
  content: ' ';
  display: block;
  width: var(--loader-spinner-height, 100px);
  height: var(--loader-spinner-width, 100px);
  border-radius: 50%;
  border: var(--loader-spinner-width, 18px) solid
    var(--loader-spinner-color-1, #000);
  border-color: var(--loader-spinner-color-1, #000) transparent
    var(--loader-spinner-color-2, #000) transparent;
  animation: dual-ring var(--loader-spinner-timing, 1.2s) linear infinite;
}
@keyframes dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
