.m4c-loader-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2147483000;
  /*pointer-events: none;*/
  display: none;

  background: var(--loader-overlay-bg-color, #fff);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: var(--loader-overlay-opacity, 0.5);
}

.m4c-loader-overlay.m4c-loading {
  display: block;
}
