.m4c-ball {
  display: block;
  position: relative;
  height: 40px;
  width: 106px;
  border: 3px solid var(--loader-spinner-color-2, #000);
  border-radius: 20px;
  box-sizing: border-box;
}
.m4c-ball:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: var(--loader-spinner-color-1, #000);
  animation: m4c-ball var(--loader-spinner-timing, 1.2s) ease-in-out infinite
    alternate;
}
@keyframes m4c-ball {
  0% {
    left: 0;
    transform: translateX(0%);
  }
  100% {
    left: 100%;
    transform: translateX(-100%);
  }
}
