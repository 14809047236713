.matgen-form {
  width: 100%;
  //max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}

#filters {
  display: flex;
  //justify-content: space-between;
  align-items: center;
  border: 1px solid #dedede;
  border-radius: 0.25em;
  margin: 0;
  margin-top: 2em;
  margin-bottom: 1em;
  padding: 1em 2.5em;
  input {
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    margin: 0 1.5em;
    width: 8vw;
  }

  #filters-label {
    display: flex;
    width: 11em;
    h4 {
      margin: 0;
      margin-left: 0.5em;
    }
  }
}

#filters-clear {
  font-weight: bold;
  font-size: 1em;
  padding: 0.01em 0.25em;
}

.color-picker-form {
  border: 1px solid #ced4da;
  padding: 0.5em;
  color: #c3c5c6;
  height: 2.5em;
  .pcr-button {
    height: 1.5em;
    position: relative;
    top: -0.15em;
  }
}

#rich-text-editor {
  min-height: 60vh;
}

#rich-text-editor .modal-body {
  min-height: 60vh;
}

#rich-text-preview {
  width: 400px;
  height: 100%;
  pointer-events: none;
  position: relative;
}

#rich-text-preview-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #ffffff99;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

#themeColorTextOpacityContainer,
#themeColorShapeOpacityContainer {
  position: relative;
  left: -1.5em;
}

#themeColorTextOpacity,
#themeColorShapeOpacity {
  width: 6em;
  margin-right: 1em;
}

#themeColorTextOpacityNumber,
#themeColorShapeOpacityNumber {
  width: 3.5em;
}

#editor-text-modal {
  .modal-body {
    padding-top: 0;
  }
}

#quill-container,
.quill-container {
  margin: auto;
  width: 400px;
  height: 100%;

  .ql-editor {
    ul {
      font-size: 1rem;
      line-height: 1.625;
    }
  }
}

#quill-output-wrapper {
  margin-top: 1500px;
}

#quill-output {
  background: transparent;
  overflow: hidden;
  text-align: left;
  p {
    margin: 0;
    padding: 0;
  }
}

.matgen-form .checkbox {
  font-weight: 400;
}
.matgen-form .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.matgen-form .form-control:focus {
  z-index: 2;
}
.matgen-form input.top,
.matgen-form select.top,
.matgen-form .checkbox.top,
.matgen-form .form-control.top {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.matgen-form textarea.form-control.top {
  margin-bottom: -17px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.matgen-form textarea.form-control.middle {
  margin-bottom: -17px;
  border-radius: 0;
}

.matgen-form input.middle,
.matgen-form select.middle,
.matgen-form .checkbox.middle,
.matgen-form .form-control.middle {
  //margin-bottom: -1px;
  border-radius: 0;
}
.matgen-form input.bottom,
.matgen-form select.bottom,
.matgen-form textarea.bottom,
.matgen-form .checkbox.bottom,
.matgen-form .form-control.bottom {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.inline-block {
  display: inline-block;
}
.form-check {
  padding-right: 1em;
}

.font-picker {
  .fp-modal {
    z-index: $modal-z-index;
  }

  .fp-modal-backdrop {
    background-color: $secondary;
    z-index: $modal-overlay-z-index;
    opacity: 0.5;
  }
}

#icon-preview {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0.5rem;
}

.matgen-form .font-picker.fp-select {
  @extend .form-control;
  margin-bottom: -1px;
  border-radius: 0;
  color: #95a5a6;
  display: block;
  padding: 0;
  .fp-fontspec {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
    margin: 0;
  }
}
