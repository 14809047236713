// Fonts
$roboto: 'Roboto', 'Open Sans', Arial, Helvetica, sans-serif;
$fontawesome: 'Font Awesome 5 Free';

// Base Colors
$white: #fff;
$black: #000;
$charcoal: #212121;
$gray: #414141;
$gray-a: #454545;
$gray-b: #d8d8d8;
$gray-c: #999;
$gray-d: #686868;
$gray-e: #848484;
$gray-light: #e1e1e1;
$gray-light-a: #e8e8e8;
$gray-light-b: #f5f5f5;

$blue-darker: #142585;
$blue-dark: #164be2;
$blue: #255dff;
$blue-light: #f1f3fa;
$blue-light-2: #dbe4fd;
$blue-muted: #e7edff;
$purple: #ae29fe;
$purple-muted: #f5e6ff;
$orange: #f04913;
$orange-muted: #ffe3d9;
$green: #00aa43;
$green-muted: #d8f6e4;

// Component Colors
$modal-overlay-color: rgba($black, 0.65);

// Settings for Transitions, Animations, Miscellaneous
$transition-slower: 0.875s ease;
$transition-slow: 0.5s ease;
$transition: 0.3s ease;
$transition-fast: 0.2s ease;
$transition-faster: 0.1s ease;

// Lists, Maps, Collections for use in Sass Functions
$colors: (
  'white': $white,
  'black': $black,
  'gray': $gray,
  'gray-a': $gray-a,
  'gray-c': $gray-c,
  'gray-light-b': $gray-light-b,
  'blue': $blue,
  'blue-dark': $blue-dark,
  'blue-darker': $blue-darker,
  'blue-light': $blue-light,
);

$headings: (h1, h2, h3, h4, h5, h6);

$common-copy: ('p', 'p > a', 'li', 'li > p', 'li > a');

// Breakpoints
// NOTE: These will probably be hard to use every time or consistently
// but try to use them or update them to fit the project so we have less
// redundancy and better consistency.

// Used for Card Grid on Step 4 of PDF generator
$card-grid-top-offset: 2.1em;
