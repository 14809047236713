@charset "utf-8";
@import 'build-vars';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import './@m4c/bootswatch';
@import 'implementation-gi';

.matgen-fullscreen-loader {
  --loader-overlay-bg-color: #fff;
  --loader-overlay-opacity: 0;
  --loader-spinner-color-1: #77bc1d;
  --loader-spinner-color-2: #77bc1d;
  --loader-spinner-timing: 1.2s;
  --loader-message-color: #77bc1d;
}

@import './loaders/loaders';

.template-mark-tested {
  display: none;
}
#m4c-fullscreen-loader-message {
  font-weight: bold;
}
#m4c-fullscreen-loader {
  z-index: 2147483647;
}

body {
  max-width: 100vw;
}

// This is here to fix the FOUC, in conjunction with style in index.html
html {
  visibility: visible;
  opacity: 1;
}
