.logic-tagging {
  $logic: &;

  &__container {
    //margin-top: 1.5rem;
    //padding: 4rem 4rem 7.25rem;
    border-radius: 3px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__heading {
      font-weight: bold;
    }

    &__button {
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    padding-top: 1.5rem;
  }

  &__material-img {
    .preview-thumb {
      position: sticky;
      top: 10rem;
      img {
        display: block;
        width: 100%;
        height: auto;
        box-shadow: 1px 1px 3px $gray-200;
      }
    }
  }

  &__card {
    //transition: $transition-fast;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1rem 0.5rem 1.5rem;
      border-radius: 3px;
    }

    &__heading {
      margin-bottom: 0;
    }

    &__help-text {
      font-size: 0.55rem;
      color: $white;
    }

    &__content {
      padding: 1.35rem 2rem 1.5rem;

      .option-buttons {
        //justify-content: flex-start;
      }
    }
  }

  &__subcard {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    box-shadow: 0 2px 2px #0000000d;

    &__help-text {
      font-size: 0.55rem;
      color: $white;
    }

    &__header {
      flex: 1 0 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1rem 0.5rem 1.5rem;
      background-color: $secondary;
    }

    &__heading {
      margin-bottom: 0;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      text-align: left;
    }

    .card--multi-select {
      flex: 1 1 100%;
      width: 100%;
      padding: 1.25rem 2rem 1.35rem;
    }

    .option-buttons {
      justify-content: flex-start;
      margin-left: -0.5rem;
      margin-right: -0.5rem;
    }

    &__multirange {
      flex: 1 1 100%;
      display: block;
      width: 100%;
      padding: 2rem 4.5rem 3rem;

      .range-slider__handle__label {
        font-weight: 500;
      }
    }
  }
}
