@import './@m4c/variables';

$tooltip-color: $secondary !default;
$tooltip-bg: lighten($primary, 15%) !default;
$tooltip-opacity: 1 !default;
$tooltip-arrow-width: 5px !default;
$tooltip-arrow-color: transparent !default;

$max-z-index: 2147483647;
$modal-z-index: 500;
$context-menu-z-index: 400;
$modal-overlay-z-index: 300;
$middle-z-index: 250;
$sidebar-z-index: 200;
$content-z-index: 100;

$web-font-path: 'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap' !default;
@import url($web-font-path);
