@import 'overlay';
@import 'animations/dual-ring';
@import 'animations/ripple';
@import 'animations/dot-circle';
@import 'animations/roller';
@import 'animations/spinner';
@import 'animations/ring';
@import 'animations/ellipsis';
@import 'animations/bars';
@import 'animations/ball';
@import 'full-screen';

.m4c-loader-anim {
  display: flex;
  align-items: center;
  justify-content: center;
}

.m4c-loader-anim.m4c-spinner,
.m4c-loader-anim.m4c-roller {
  display: block;
}

.m4c-button-loader {
  position: relative;
}

.m4c-element-loader,
.m4c-button-loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
