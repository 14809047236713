.m4c-ring {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
}
.m4c-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 90px;
  height: 90px;
  margin: 8px;
  border: 18px solid var(--loader-spinner-color-1, #000);
  border-radius: 50%;
  animation: m4c-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--loader-spinner-color-1, #000) transparent transparent
    transparent;
}
.m4c-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.m4c-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.m4c-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes m4c-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
